import { useState } from 'react'

/**
 * A custom hook for managing the conversation between the user and the AI.
 *
 * @returns {Object} An object containing the `messages` array and the `addMessage` function.
 */
const useMessageCollection = () => {
  const initialMsg = {
    id: 1,
    createdAt: Date.now(),
    text: `مرحبا بكم في موقعنا المتميز الذي يعتبر مركزًا رائدًا في مجال الجمال والأناقة. نقدم لكم منصة رقمية مبتكرة تعتمد على تقنيات الذكاء الاصطناعي والتواصل عبر الشات لتوفير تجربة بحث فريدة ومتطورة. هدفنا هو مساعدتكم على العثور على أفضل الخدمات والمنتجات المتعلقة بالجمال والأناقة بسهولة وفعالية، مما يضمن لكم توفير الوقت والجهد والحصول على نتائج مرضية تلبي احتياجاتكم وتطلعاتكم. استمتعوا بتجربة تصفح فريدة واستفيدوا من ميزاتنا المتقدمة لتحسين جودة حياتكم وإضفاء لمسة من الجمال والتألق على إطلالتكم.
    
    Welcome to our distinguished website, a leading hub in the realm of beauty and elegance. We offer you an innovative digital platform that relies on artificial intelligence technologies and chat-based communication to provide a unique and advanced search experience. Our goal is to help you easily and effectively find the best beauty and elegance-related services and products, ensuring you save time and effort while achieving satisfying results that meet your needs and aspirations. Enjoy a one-of-a-kind browsing experience and take advantage of our advanced features to enhance your quality of life and add a touch of beauty and brilliance to your appearance.`,
    ai: true
  }
  const [messages, setMessages] = useState([initialMsg]);

  /**
  * A function for adding a new message to the collection.
  *
  * @param {Object} message - The message to add to the collection.
  */
  const addMessage = (message) => {
    setMessages((prev) => [...prev, message]);
  }

  const clearMessages = () => setMessages([initialMsg])

  return [messages, addMessage, clearMessages];
}

export default useMessageCollection